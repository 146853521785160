import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import {
  Container,
  Content,
  Logo,
  MenuDrawer,
  MenuHeader,
  MenuBackIcon,
  MenuHeaderText,
  MobileMenuButton,
  Navigation,
  NavLink,
  MenuContainer,
  MenuLink,
} from "./styles";

import LogoAlma from "../../assets/images/logo_alma_blue.png";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu_icon.svg";
import { ReactComponent as SetaEsquerda } from "../../assets/icons/seta_direcional_esquerda.svg";

const NavMenu = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let screenHeight = window.screen.height;
      let windowHeight = window.scrollY;

      windowHeight >= screenHeight - 190
        ? setIsSticky(true)
        : setIsSticky(false);
    }
  };

  const handleScroll = (id, category, action, label) => {
    if (window !== undefined) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop - 80,
        behavior: "smooth",
      });
    }
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleScrollMobile = (id, category, action, label) => {
    toggleDrawer();
    handleScroll(id, category, action, label);
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <Container isSticky={isSticky}>
        <Content isSticky={isSticky}>
          {isSticky && (
            <>
              <Logo src={LogoAlma} alt="Alma Sírio-Libânes" />
              <MobileMenuButton onClick={toggleDrawer}>
                <MenuIcon />
              </MobileMenuButton>
            </>
          )}
          <Navigation>
            <NavLink
              onClick={() =>
                handleScroll(
                  "about",
                  "Menu de navegação",
                  "Navega para a seção quem somos",
                  "ClickedOnQuemSomos"
                )
              }
            >
              Quem somos
            </NavLink>
            <NavLink
              onClick={() =>
                handleScroll(
                  "areas",
                  "Menu de navegação",
                  "Navega para a seção nossas áreas",
                  "ClickedOnNossasAreas"
                )
              }
            >
              Nossas áreas
            </NavLink>
            <NavLink
              onClick={() =>
                handleScroll(
                  "ecosystem",
                  "Menu de navegação",
                  "Navega para a seção ecossistema",
                  "ClickedOnEcossistema"
                )
              }
            >
              Ecossistema
            </NavLink>
            <NavLink
              onClick={() =>
                handleScroll(
                  "part",
                  "Menu de navegação",
                  "Navega para a seção faça parte",
                  "ClickedOnFacaParte"
                )
              }
            >
              Faça parte
            </NavLink>
          </Navigation>
        </Content>
      </Container>
      <MenuDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        variant="temporary"
      >
        <MenuContainer>
          <MenuHeader>
            <MenuBackIcon onClick={toggleDrawer}>
              <SetaEsquerda />
            </MenuBackIcon>
            <MenuHeaderText onClick={toggleDrawer}>Voltar</MenuHeaderText>
          </MenuHeader>
          <MenuLink onClick={() => handleScrollMobile("home")}>Início</MenuLink>
          <MenuLink
            onClick={() =>
              handleScrollMobile(
                "about",
                "Menu de navegação",
                "Navega para a seção quem somos",
                "ClickedOnQuemSomos"
              )
            }
          >
            Quem somos
          </MenuLink>
          <MenuLink
            onClick={() =>
              handleScrollMobile(
                "areas",
                "Menu de navegação",
                "Navega para a seção nossas áreas",
                "ClickedOnNossasAreas"
              )
            }
          >
            Nossas áreas
          </MenuLink>
          <MenuLink
            onClick={() =>
              handleScrollMobile(
                "ecosystem",
                "Menu de navegação",
                "Navega para a seção ecossistema",
                "ClickedOnEcossistema"
              )
            }
          >
            Ecossistema
          </MenuLink>
          <MenuLink
            onClick={() =>
              handleScrollMobile(
                "part",
                "Menu de navegação",
                "Navega para a seção faça parte",
                "ClickedOnFacaParte"
              )
            }
          >
            Faça parte
          </MenuLink>
        </MenuContainer>
      </MenuDrawer>
    </>
  );
};

export default NavMenu;
