import styled from "styled-components";
import { injectMotion } from "@jota-ds/motion-tokens/helpers.js";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

export const NavGroup = styled.div`
  display: flex;
  button:first-of-type {
    margin-right: 1rem;
  }
`;

export const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  &::before {
    z-index: 0;
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.25s, transform 0.2s;
    background-color: #c5d7fb52;
    border-radius: 50%;
  }
  > * {
    z-index: 1;
  }
  &:hover:not(:disabled) {
    &::before {
      opacity: 1;
      transform: scale(1);
      background-color: #c5d7fb52;
    }
  }
  &:active:not(:disabled) {
    background-color: transparent;
    &::before {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
    }
  }

  &:disabled {
    cursor: auto;
    color: #000000;
    opacity: 0.32;
  }
  :focus-visible {
    outline: 0.125rem auto #ff8a00;
  }
  color: #2c6ef2;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  ${injectMotion("switch-slow")}
`;
