import React from "react";
import {
  Wrapper,
  Container,
  Image,
  Content,
  Title,
  ContainerTitle,
  Divider,
  Paragraph,
  TopicList,
  Topic,
  ImageContainer,
  ContainerText,
} from "./styles";

import Women from "../../assets/images/alma-hsl-colaborador.png";
import Heart from "../../assets/icons/heart.png";
import Rocket from "../../assets/icons/rocket.png";
import AlmaLogo from "../../assets/icons/alma_logo.png";

const WhatMovesUs = () => {
  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <Image
            src={Women}
            alt="Imagem de uma colaboradora sorrindo, sentada em uma mesa utilizando notebook."
          />
        </ImageContainer>
        <Content>
          <ContainerTitle>
            <Title>O que nos move</Title>
            <Divider />
          </ContainerTitle>
          <Paragraph>
            <p>
              <strong>
                Na prática, Alma Sírio-Libanês é um espaço que reúne mentes
                criativas para construir a Saúde do futuro.
              </strong>{" "}
              Reunímos todas as operações de inovação e tecnologia para co-criar
              soluções e novos negócios juntos com os nossos{" "}
              <strong>mais de 9 mil colaboradores e corpo clínico.</strong>
            </p>
            <p>
              Queremos cuidar da jornada de saúde das pessoas por toda a vida,
              através de um ecossistema físico-digital integrado, dentro e fora
              do hospital.
            </p>
          </Paragraph>
          <TopicList>
            <Topic>
              <img src={Heart} alt="Ícone de coração" />
              <ContainerText>
                <h2>Open Health</h2>
                <p>
                  Tornar os aspectos da saúde interoperáveis, transparentes e
                  acessíveis.
                </p>
              </ContainerText>
            </Topic>
            <Topic>
              <img src={Rocket} alt="Ícone de foguete" />
              <ContainerText>
                <h2>Venture Builder</h2>
                <p>
                  Atrair startups e empresas para, em parceria, construir
                  produtos e soluções, que serão testados internamente e poderão
                  ser aplicados em outras instituições.
                </p>
              </ContainerText>
            </Topic>
            <Topic>
              <img src={AlmaLogo} alt="Ícone do logo da marca Alma" />
              <ContainerText>
                <h2>Tecnologia com Alma</h2>
                <p>
                  Construímos negócios mais autênticos, tecnologias com
                  propósito e uma economia mais sustentável.
                </p>
              </ContainerText>
            </Topic>
          </TopicList>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default WhatMovesUs;
