import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 69.875rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 8.5rem;

  @media (max-width: 1170px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto 7.625rem;
  }
`;

export const Heading = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 124%;
  color: #202538;
  margin-bottom: 2.5rem;

  @media (max-width: 648px) {
    font-size: 1.5rem;
  }
`;
