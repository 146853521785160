import React from "react";
import ReactDOM from "react-dom";
import Home from "./pages/home";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TechEstagio from "./pages/techestagio";

// Routes

const routes = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "techestagio",
        element: <TechEstagio />,
      }
    ]
  }
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={routes} />
  </React.StrictMode>,
  document.getElementById("root")
);
