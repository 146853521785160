import React from "react";
import { captureOutboundLink } from "../../utils/captureOutboundLink";
import {
  Wrapper,
  Container,
  BoxOne,
  Title,
  Image,
  BoxTwo,
  ContainerTitle,
  ContentTitle,
  Divider,
  Subtitle,
  Paragraph,
  Button,
  Link,
} from "./styles";

import ProgramaTechEstagio from "../../assets/images/alma-hsl-programatechestagio2024.png";
import ArrowRight from "../../assets/icons/arrow_right_dark_blue.png";

const BePartOfIt = () => {
  return (
    <Wrapper id="part">
      <Container>
        <BoxOne>
          <Title>Faça parte</Title>
          <Image
            src={ProgramaTechEstagio}
            alt="Imagem de uma mulher jovem sentada e sorrindo, olhando para um notebook. Toda a imagem está inserida na forma da letra A da marca Alma HSL e junto à forma tem a marca do Programa Tech Estágio 2022."
          />
        </BoxOne>
        <BoxTwo>
          <ContainerTitle>
            <ContentTitle>Programa Tech Estágio</ContentTitle>
            <Divider />
          </ContainerTitle>
          <Subtitle>Transformando a saúde com tecnologia e inovação</Subtitle>
          <Paragraph>
            Alma e Eureca uniram forças para impulsionar novas carreiras e
            conectar jovens de diferentes origens que queiram revolucionar o
            cuidado com as pessoas por meio da tecnologia e inovação.
          </Paragraph>
          <Button
            href="https://techestagio.hsl.org.br"
            target="_blank"
            rel="noopener noreferrer"
            onClick={captureOutboundLink("https://techestagio.hsl.org.br")}
          >
            Inscrições encerradas{" "}
          </Button>

          <ContainerTitle>
            <ContentTitle>Vagas Tech</ContentTitle>
            <Divider />
          </ContainerTitle>
          <Paragraph>
            Confira nossas vagas disponíveis em nosso LinkedIn e seja um(a)
            colaborador(a) Alma.
          </Paragraph>

          <Link>
            <a
              href="https://www.linkedin.com/company/hospitalsiriolibanes/jobs/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={captureOutboundLink(
                "https://www.linkedin.com/company/hospitalsiriolibanes/jobs/"
              )}
            >
              Ir para LinkedIn{" "}
              <img src={ArrowRight} alt="Ícone com seta para direita" />
            </a>
          </Link>
        </BoxTwo>
      </Container>
    </Wrapper>
  );
};

export default BePartOfIt;
