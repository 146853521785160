import styled, { css } from "styled-components";

const transition = css`
  transition: transform 0.45s;
`;

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.5rem;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 69.875rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Heading = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 124%;
  color: #202538;
  margin: 6.375rem 0 2.75rem;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export const TabsWrapper = styled.div`
  max-width: 26.813rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    max-width: 19.5rem;
  }
`;

export const LogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin-top: 1.875rem;
    margin-bottom: 4.625rem;
  }
`;

export const ContentContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 26.813rem;
  height: 16.5rem;

  @media (max-width: 1024px) {
    width: 19.5rem;
    height: 15.5rem;
  }
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  transform: translateX(
    ${(props) => (props.active === 0 ? 0 : `-${props.active * 429}px`)}
  );
  ${transition}
  color: #3D3B40;
  font-size: 1rem;
  font-family: "Helvetica";
  margin-top: 2rem;

  @media (max-width: 1024px) {
    transform: translateX(
      ${(props) => (props.active === 0 ? 0 : `-${props.active * 312}px`)}
    );
  }
`;

export const Tab = styled.div`
  width: 26.813rem;

  @media (max-width: 1024px) {
    width: 19.5rem;
  }
`;

export const TabNav = styled.div`
  display: flex;
  width: 26.813rem;

  @media (max-width: 1024px) {
    width: 19.5rem;
  }
`;

export const LogoShape = styled.div`
  width: 8.165625rem;
  height: 8.375rem;
  box-shadow: 0rem 0.5rem 1.5rem rgba(0, 0, 0, 0.16);
  border-radius: 6.25rem;
  border: 0.063rem solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;

  &:first-of-type {
    margin: 0;
  }

  @media (max-width: 1024px) {
    width: 5.579375rem;
    height: 5.721875rem;
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 6rem;

  @media (max-width: 1024px) {
    max-width: 4.099375rem;
  }
`;

export const NewsCardContainer = styled.div`
  display: flex;
  width: min-content;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    margin-top: 1rem;
  }
`;

export const NewsCard = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  color: #000000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 133%;
  border-radius: 16px;

  letter-spacing: -0.01em;

  a {
    color: #2c6ef2;
    text-decoration: none;
  }

  ${(props) =>
    props.card === 1
      ? "width: 210.15px; margin-top: 0.5rem; padding: 2rem 1rem;"
      : props.card === 2
      ? "width: 274px; margin-bottom: 1.25rem; padding: 0.875rem 1.25rem;"
      : props.card === 3
      ? "width: 221px; margin-left: 6.5625rem; padding: 1.5rem;"
      : props.card === 4
      ? "width: 264px; margin-right: 2.25rem; padding: 2rem 1.25rem 1rem;"
      : props.card === 5 &&
        "width: 235px; margin-top: 1rem; padding: 1rem 1.5rem;"}

  @media (max-width: 1024px) {
    padding: 1rem;
    width: 100%;
    max-width: 19.5rem;
    margin: 0 0 1rem;
  }
`;

export const NewsCardImage = styled.div`
  display: flex;
  width: 100%;
`;

export const NewsCardImageText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
`;

export const NewsCardTop = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }
`;

export const NewsCardBottom = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NewsCardTopRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;

  @media (max-width: 1024px) {
    margin: 0;
    align-items: center;
  }
`;
