import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4fafc;
  @media (max-width: 648px) {
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 80.875rem;
  display: flex;
  align-items: center;
  margin-bottom: 5.375rem;

  @media (max-width: 648px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const BoxOne = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  margin-top: 3.375rem;
  width: 100%;
  @media (max-width: 648px) {
    width: 100%;
    margin-top: 1.5rem;
  }
`;

export const BoxTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  margin: 2rem 0 0 3.875rem;

  @media (max-width: 648px) {
    margin: 1rem 0 0 -0.5rem;
    width: 100%;
    justify-content: start;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #202538;
  @media (max-width: 648px) {
    width: 100%;
    font-size: 1.5rem;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.313rem;
  background: linear-gradient(270deg, #8300ff 50%, #14d2e1 80%);
  /* margin-top: 1.5rem; */

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  width: 80%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #59585e;
  margin: 1.5rem 0 20px 0;
  @media (max-width: 648px) {
    width: 100%;
    font-size: 1rem;
  }
`;

export const Button = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 1.5rem;
  gap: 0.5rem;
  width: 40%;
  height: 3rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  color: #ffffff;
  text-decoration: none;
  margin-top: 20px;

  background: ${(props) => (props.success ? "#888888" : "#2c6ef2")};
  border-radius: 31.25rem;
  border: none;
  cursor: ${(props) => (props.success ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.success ? "#888888" : "#ffff")};
    color: ${(props) => (props.success ? "#555555" : "#2c6ef2")};
    border: ${(props) =>
      props.success ? "1px solid #888888" : "1px solid #2c6ef2"};
  }

  @media (max-width: 648px) {
    padding: 0;
    width: 100%;
  }
`;

export const InputBox = styled.div`
  width: 90%;
  background-color: #ffff;
  padding: 40px 20px 0;

  @media (max-width: 648px) {
    width: 100%;
    padding: 5px;
    margin-top: 20px;
  }
`;

export const Input = styled.input`
  /* background-image: linear-gradient(#20aee3, #20aee3),
    linear-gradient(#bfbfbf, #bfbfbf); */
  border: 0 none;
  border-radius: 5px;
  box-shadow: none;
  float: none;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: 0s ease-out 0s;
  color: #8f9194;
  min-height: 35px;
  display: initial;
  width: 100%;
  outline: none;
  font-size: 15px;

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: row;
  margin-bottom: 16px;

  @media (max-width: 648px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const InputDiv = styled.div`
  width: 100%;
`;
