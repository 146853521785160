import styled, { css, keyframes } from "styled-components";
import Drawer from "@mui/material/Drawer";

const FadeInAnimation = keyframes`
  from {opacity: 0} to {opacity: 1}
`;

export const Container = styled.div`
  z-index: 999;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => (props.isSticky ? "#292929" : "#fff")};
  background-color: ${(props) => props.isSticky && "#fff"};
  position: ${(props) => (props.isSticky ? "fixed" : "absolute")};
  left: 0;
  top: ${(props) => (props.isSticky ? "0" : "80px")};
  animation: ${(props) =>
    props.isSticky
      ? css`
          ${FadeInAnimation} 1s ease-in-out
        `
      : ""};

  ${(props) =>
    props.isSticky &&
    '&::before { content: ""; height: 0.313rem; width: 100%; background: linear-gradient(270deg, #8300FF 49.88%, #14D2E1 79.81%); }'}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) =>
    props.isSticky ? "1.5rem 1rem 0.875rem" : "5.5rem 0 0"};

  @media (max-width: 648px) {
    width: calc(100% - 3rem);
    justify-content: space-between;
    margin: 0;
    height: 5.188rem;
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 8.563rem;
  height: 100%;
  max-height: 4.813rem;
  margin-right: 9.5rem;

  @media (max-width: 648px) {
    margin: 0;
    max-width: 4.448rem;
    max-height: 2.5rem;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 33.5rem;

  @media (max-width: 648px) {
    display: none;
  }
`;

export const NavLink = styled.span`
  font-family: "Inter", sans-serif;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.438rem;
  letter-spacing: -0.02em;
  transition: all 0.3s;

  :hover {
    color: #00aaf1;
  }
`;

export const MobileMenuButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  border: unset;
  background: unset;
  margin: 0;
  padding: 0;

  @media (max-width: 648px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MenuDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorRight {
    width: 100%;
    color: #292929;
  }
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MenuHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 0.063rem solid #e0e0e0;
  padding: 1.125rem 1.5rem;
`;

export const MenuBackIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuHeaderText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 133%;
  letter-spacing: -0.01em;
  margin-left: 1rem;
`;

export const MenuLink = styled.div`
  width: 100%;
  padding: 1.25rem 1.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
`;
