import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: "";
    width: 100%;
    height: 0.313rem;
    background: linear-gradient(270deg, #8300ff 50%, #14d2e1 80%);
  }

  @media (max-width: 648px) {
    &::before {
      display: none;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 69.875rem;
  min-height: 42.375rem;
  display: flex;
  align-items: center;

  > img {
    margin-top: 1.25rem;
    width: 100%;
    max-width: 35.25rem;
  }

  @media (max-width: 1024px) {
    width: calc(100% - 3rem);
    flex-direction: column-reverse;
    margin: 0 auto;

    > img {
      width: 100%;
    }
  }
`;

export const TextCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 1.063rem;

  > h1 {
    margin: 0 0 2.5rem;
    max-width: 32.688rem;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 124%;
    color: #202538;
  }

  > p {
    margin: 0;
    max-width: 28.563rem;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: #3d3b40;
  }

  > span {
    display: flex;
    width: min-content;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    color: #3977f3;
    margin-top: 2rem;
    border-bottom: 1px solid #3977f3;
    cursor: pointer;

    > p {
      min-width: 8rem;
      margin: 0;
    }

    > img {
      margin-left: 0.5rem;
      width: 0.75rem;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 auto;
    max-width: 35.25rem;

    > h1 {
      font-size: 1.5rem;
      margin: 1.5rem 0 1rem;
    }
  }
`;
