import React from "react";

// import { Container } from './styles';

function TechEstagio() {
  React.useEffect(() => {
    window.location.href = "https://techestagio.hsl.org.br";
  }, []);

  return <div />;
}

export default TechEstagio;
