import React, { useEffect, useState } from "react";
import { Wrapper, NavGroup } from "./styles";
import StepsNav from "./StepsNav";
import { ButtonContainer } from "./styles";

import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right.svg";

const CarouselNav = ({
  current = 0,
  steps = 1,
  prevClick,
  nextClick,
  dotClick,
}) => {
  const [_current, setCurrent] = useState(current);

  useEffect(() => {
    setCurrent(current);
  }, [current]);

  const _handleChange = (index) => {
    setCurrent(index);
  };

  const _handlePrev = () => {
    if (_current > 0) {
      _handleChange(_current - 1);
      prevClick();
    }
  };
  const _handleNext = () => {
    if (_current < steps - 1) {
      _handleChange(_current + 1);
      nextClick();
    }
  };

  return (
    <Wrapper>
      <StepsNav current={_current} steps={steps} handleChange={dotClick} />
      <NavGroup>
        <ButtonContainer
          type="button"
          disabled={_current === 0}
          onClick={_handlePrev}
          aria-label="Ir para anterior"
        >
          <ArrowLeft />
        </ButtonContainer>
        <ButtonContainer
          type="button"
          disabled={_current === steps - 1}
          onClick={_handleNext}
          aria-label="Ir para próximo"
        >
          <ArrowRight />
        </ButtonContainer>
      </NavGroup>
    </Wrapper>
  );
};

export default CarouselNav;
