import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 69.875rem;
  display: flex;
  align-items: center;
  border-top: 0.063rem solid #e0e0e0;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  @media (max-width: 648px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto;
    align-items: flex-start;
  }
`;

export const Image = styled.img`
  margin-right: 1.5rem;

  @media (max-width: 648px) {
    margin-bottom: 1.656rem;
  }
`;

export const Texts = styled.div`
  font-family: "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #3d3b40;
  display: flex;
  align-items: center;
  margin-right: 2.374rem;

  p:first-child {
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #3d3b40;
    margin-right: 1rem;
  }

  a {
    color: #3d3b40;
  }

  @media (max-width: 648px) {
    width: 100%;
    margin-bottom: 2.5rem;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 2.25rem;
  }
`;
