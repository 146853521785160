import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 80.313rem;
  min-height: 42.375rem;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  max-width: 39.25rem;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 2.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.75rem;

  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    max-width: 39.25rem;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 116%;
  letter-spacing: -0.02em;
  color: #202538;

  @media (max-width: 767px) {
    margin: 2.042rem 0 1rem;
  }
`;

export const Divider = styled.div`
  width: 13.893rem;
  height: 0.313rem;
  background: linear-gradient(270deg, #8300ff 50%, #14d2e1 80%);
  margin-top: 0.255rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  max-width: 31.5rem;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #3d3b40;
  margin: 1.5rem 0 1.563rem;

  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 0 2rem;
  }
`;

export const TopicList = styled.div``;

export const Topic = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
    color: #3d3b40;
    margin: 0;
  }

  p {
    max-width: 31.5rem;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: #59585e;
    margin: 0.25rem 0 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      margin-bottom: 0.5rem;
    }

    p {
      max-width: 100%;
    }
  }
`;

export const ContainerText = styled.div`
  margin: 0.25rem 0 0 1.375rem;

  @media (max-width: 767px) {
    margin: 0.688rem 0 0;
  }
`;
