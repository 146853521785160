import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #692996 0%, #004098 100%);
`;

export const Container = styled.div`
  width: 100%;
  max-width: 80.313rem;
  max-height: 38.75rem;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    max-width: calc(100% - 3rem);
    height: auto;
    flex-direction: column-reverse;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 24rem;
  margin-right: 4.375rem;
  margin-left: 6.563rem;

  @media (max-width: 1024px) {
    margin: -3.5rem 0 3.75rem;
  }
`;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 116%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0 0 2.313rem;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  max-width: 21.125rem;
  margin: 0 0 1.5rem;
`;

export const Link = styled.div`
  max-width: 21.125rem;
  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #14d2e2;
  }

  img {
    margin-left: 0.583rem;
    margin-right: 1.582rem;
  }
`;

export const CircleImage = styled.img`
  position: absolute;
  top: -8.5rem;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 18.75rem;
    position: relative;
    top: -4.5rem;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 38.75rem;

  @media (max-width: 1024px) {
    justify-content: center;
    height: auto;
  }
`;
