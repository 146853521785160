import React from "react";
import { captureOutboundLink } from "../../utils/captureOutboundLink";
import {
  Wrapper,
  Container,
  Content,
  Title,
  Text,
  Link,
  CircleImage,
  ContainerImage,
} from "./styles";

import ArrowRight from "../../assets/icons/arrow_right.png";
import ManWithNotebook from "../../assets/images/alma-hsl-openinnovation-jovem.png";

const OpenInnovation = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>Open Innovation</Title>
          <Text>
            O programa de inovação aberta promovido pela Alma Sírio-Libanês está
            em busca de parceiros para impulsionar o ecossistema de inovação em
            saúde, por meio da cocriação e desenvolvimento de soluções com o
            objetivo de gerar resultado, excelência operacional e impacto
            social.
          </Text>
          <Link>
            <a
              href="https://aevo.com.br/connect/alma-hsl"
              target="_blank"
              rel="noopener noreferrer"
              onClick={captureOutboundLink(
                "https://aevo.com.br/connect/alma-hsl"
              )}
            >
              Saiba mais sobre o nosso programa de inovação aberta
              <img src={ArrowRight} alt="Ícone com seta para direita" />
            </a>
          </Link>
        </Content>
        <ContainerImage>
          <CircleImage
            src={ManWithNotebook}
            alt="Imagem de homem em pé sorrindo, segurando um notebook. Na sua frente e sem foco, um monitor com linhas de código. Toda a imagem está inserida na forma da letra A da marca Alma HSL."
          />
        </ContainerImage>
      </Container>
    </Wrapper>
  );
};

export default OpenInnovation;
