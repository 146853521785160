import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url(https://media.graphassets.com/raJn3qwET7i2o5zcOYfx);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 58.25rem;
  z-index: 1;

  span {
    font-size: 2.5rem;
  }

  @media (max-width: 648px) {
    span {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 29.938rem;
  height: 100%;
  max-height: 13.063rem;

  @media (max-width: 648px) {
    max-width: 14.25rem;
  }
`;
