import styled, { css } from "styled-components";

export const Row = styled.div`
  display: flex;
  position: relative;
`;

const transition = css`
  transition: transform 0.45s;
`;

export const Underline = styled.div`
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 50%;
  height: 0.125rem;
  background: #82c8f5;
  transform: translateX(
    ${(props) => (props.active === 0 ? 0 : props.active * 100)}%
  );
  ${transition}
`;

export const Button = styled.button`
  flex: 1 1 33.33%;
  outline: none;
  height: 2.5rem;
  cursor: pointer;
  font-family: "Helvetica";
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  background: #fff;
  padding: 0;
  width: 6.438rem;
  color: ${(props) => (props.active ? "#003A94" : "#292929")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
`;
