import React from "react";
import { Button, Row, Underline } from "./styles";

const Tabs = ({ active, setActive, tabs }) => {
  return (
    <Row>
      <Underline active={active} />
      {tabs.map((tab, idx) => (
        <Button key={`button-${idx}`} active={active === idx} onClick={() => setActive(idx)}>
          {tab.title}
        </Button>
      ))}
    </Row>
  );
};

export default Tabs;
