import ReactGA from "react-ga4";

export const captureOutboundLink = (url) => {
  ReactGA.ga("send", "event", "outbound", "click", url, {
    transport: "beacon",
    hitCallback: function () {
      document.location = url;
    },
  });
};
