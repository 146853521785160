import React from "react";

import { Container, Heading, Wrapper } from "./styles";
import CardCarousel from "../CardCarousel";

import AlmaHSLAreaDeInovacao from "../../assets/images/alma-hsl-areadeinovacao.png";
import AlmaHSLAreaDeProduto from "../../assets/images/alma-hsl-areadeproduto.png";
import AlmaHSLAreaDeAgilidade from "../../assets/images/alma-hsl-areadeagilidade.png";
import AlmaHSLAreaDeDesign from "../../assets/images/alma-hsl-areadedesign.png";
import AlmaHSLAreaDeDesenvolvimento from "../../assets/images/alma-hsl-areadedesenvolvimento.png";
import AlmaHSLAreaDeGaragem from "../../assets/images/alma-hsl-areadegaragem.png";
import AlmaHSLAreaDeDataLab from "../../assets/images/alma-hsl-areadedatalab.png";
import AlmaHSLAreaDeCyberSecurity from "../../assets/images/alma-hsl-areadecybersecurity.png";
import AlmaHSLAreaDeSistemasAssistenciais from "../../assets/images/alma-hsl-areadesistemasassistenciais.png";
import AlmaHSLAreaDeInfraestrutura from "../../assets/images/alma-hsl-areadeinfraestrutura.png";
import AlmaHSLAreaDeNoc from "../../assets/images/alma-hsl-areadenoc.png";
import AlmaHSLAreaDeOperacaoDeTi from "../../assets/images/alma-hsl-areadeoperacaodeti.png";
import AlmaHSLAreaDeImagens from "../../assets/images/alma-hsl-areadeimagensmedicaseia.png";
import AlmaHSLAreaDeSistemasAdministrativos from "../../assets/images/alma-hsl-areadesistemasadministrativos.png";
import AlmaHSLAreaDeEngenhariaDeDados from "../../assets/images/alma-hsl-areadeengenhariadedados.png";

const OurAreas = () => {
  const cards = [
    {
      area: {
        src: AlmaHSLAreaDeInovacao,
        alt: "Imagem de um homem profissional de saúde que clica em um painel transparente de figuras relacionadas à área de saúde na cor ciano, representando inovação tecnológica.",
        title: "Inovação",
        desc: "O time de inovação está observando todo o ecossistema de saúde (e além dele) e encontrando parceiros ou oportunidades para o desenvolvimento colaborativo.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeProduto,
        alt: "Imagem de duas pessoas mexendo em papéis espalhados em uma mesa, construindo a ideia de um produto.",
        title: "Produto",
        desc: "Nosso time de produtos tem a capacidade de entender e antecipar as necessidades dos nossos diversos clientes e as traduzir para soluções que entregam uma ótima experiência.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeAgilidade,
        alt: "Imagem de uma mão apertando um botão com a palavra 'story 3'.",
        title: "Agilidade",
        desc: "A agilidade é um pilar importante para a transformação digital e a construção de soluções para problemas complexos ao abordar esses com empirismo, transparência, coragem e adaptabilidade, trazendo os clientes e colaboradores como partes ativas do processo de construção.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeDesign,
        alt: "Imagem de uma mulher em frente a um monitor que apresenta elementos geométricos e a palavra 'design'.",
        title: "Design",
        desc: "Área responsável por fomentar a qualidade das interações dos produtos e serviços, visando a excelência da experiência, da usabilidade, da comunicabilidade e da acessibilidade - com foco no usuário.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeDesenvolvimento,
        alt: "Imagem de um desenvolvedor em frente a um monitor e a um notebook, apontando para as linhas de código do notebook.",
        title: "Desenvolvimento",
        desc: "O time de desenvolvimento é responsável por materializar as visões de produtos em uma solução ideal para cada contexto, fazendo uso de tecnologias de ponta para entregar uma solução fluída, estável e escalável.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeGaragem,
        alt: "Imagem de uma profissional de saúde utilizando um óculos de realidade virtual apontando para um dado digital a sua frente.",
        title: "Garagem",
        desc: "A Garagem é uma área de prototipação e construção de MVPs em períodos curtos e fazendo o uso de tecnologias não necessariamente triviais, como as novas redes 5G, tecnologias de automação e realidade aumentada para áreas onde ainda não estão consolidadas.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeDataLab,
        alt: "Imagem de um notebook, focando no teclado com números e linhas sobrepostos.",
        title: "Data Lab",
        desc: "O DataLab explora novos conhecimentos através de dados complexos aplicando metodologia científica para a melhor decisão. Avaliamos e implementamos algoritmos de inteligência artificial para melhorar o cuidado do paciente e gerar valor para o negócio, sempre com foco em melhores práticas e imparcialidade.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeCyberSecurity,
        alt: "Imagem de pontos interligados. No centro de cada ponto, uma armadura de chave desenhada.",
        title: "CyberSecurity",
        desc: "Área que é responsável pela proteção dos dados e da privacidade dos nossos pacientes, visando a excelência em filantropia e saúde de alta complexibilidade na América Latina através da segurança.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeSistemasAssistenciais,
        alt: "Imagem de um grupo de cinco pessoas conversando, formando um mini círculo e todos olhando para uma mulher falando.",
        title: "Sistemas Assistenciais",
        desc: "Times que atuam de forma estratégica juntamente com as equipes assistenciais. Com o objetivo de atender as demandas e projetos alinhados com a estratégia institucional. Além disso, tem como responsabilidade garantir o bom funcionamento dos sistemas que estão sob gestão dessa equipe.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeInfraestrutura,
        alt: "Imagem de um corredor de servidores ligados e uma sobreposição de ligação entre eles desenhada na imagem.",
        title: "Infraestrutura",
        desc: "Time responsável por fazer a sustentação de todos os sistemas, servidores, serviços e infraestrutura de dados de telecom. Além de implementar e manter as políticas e ações desenhadas pela área de segurança da informação.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeNoc,
        alt: "Imagem focada nas mãos de um homem operando dados digitais.",
        title: "NOC",
        desc: "Área que monitora todos os sistemas, serviços e infraestrutura - que garante o escalonamento de todas as equipes necessárias em caso da identificação de um incidente ou possível incidente, assegurando a continuidade de toda a operação do hospital.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeOperacaoDeTi,
        alt: "Imagem de um atendente sorrindo em frente a um computador.",
        title: "Operação de TI",
        desc: "Equipes que ficam à frente da TI e dos clientes com o objetivo de atender os incidentes e solicitações da área. Dividem-se em: 1) Primeiro nível - incidentes pequenos; 2) Segundo nível - incidentes específicos e documentados; 3) Field Service - incidentes de usuários-clientes.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeImagens,
        alt: "Imagem de dois profissionais de saúde segurando um tablet com a imagem de um raiox-x de pulmão.",
        title: "Imagens Médicas & IA",
        desc: "Área responsável pelos projetos e produção de exames de imagens diagnósticas, métodos gráficos, laudos, Inteligência Artificial de Imagens e 3D - apoiando os setores do CDI no fluxo de exames e de entrega de resultados.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeSistemasAdministrativos,
        alt: "Imagem de um profissional de tecnologia em frente a um monitor com a visualização de sistema.",
        title: "Sistemas Administrativos",
        desc: "Equipes que atendem as iniciativas originadas de áreas administrativas/ backoffice do Hospital relacionadas à implantação de novos sistemas, desenvolvimento de produtos ou aplicação de tecnologias que visam ganhos aos nossos processos e controles.",
      },
    },
    {
      area: {
        src: AlmaHSLAreaDeEngenhariaDeDados,
        alt: "Imagem de um globo com conexões.",
        title: "Engenharia de Dados",
        desc: "Área responsável pela disponibilidade de dados que viabilizam análises AD-HOC, estudos estruturados e insights com o uso de Ciência de Dados e algoritmos complexos - auxiliando a promoção de uma vida plena e digna aos pacientes.",
      },
    },
  ];

  return (
    <Wrapper id="areas">
      <Container>
        <Heading>Nossas áreas</Heading>
        <CardCarousel items={cards} />
      </Container>
    </Wrapper>
  );
};

export default OurAreas;
