import styled from "styled-components";

export const CardContainer = styled.li`
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);

  display: flex;
  flex: 1;
  flex-direction: column;
  width: 22.25rem;
  height: 42.9rem;
  border-radius: 1rem;
  box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.08);
  position: relative;
  transition: transform calc(var(--base-duration) / 2) var(--base-ease);
  margin-left: 1.125rem;

  &:first-of-type {
    margin: 0;
  }

  @media (max-width: 648px) {
    width: 19.5rem;
    height: 37.5rem;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 18.813rem;
  border-radius: 1rem 1rem 0 0;
  object-fit: cover;

  @media (max-width: 648px) {
    max-height: 11.25rem;
  }
`;

export const CardContent = styled.div`
  height: 2000%;

  padding: 2.5rem;

  > h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 133%;

    letter-spacing: -0.01em;

    color: #202538;
    margin: 0 0 0.75rem;
  }

  > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;

    color: #3d3b40;
    margin: 0;
  }

  @media (max-width: 648px) {
    padding: 2.5rem 2rem;
  }
`;
