import React from "react";
import { DialogTitle, IconButton } from "@mui/material";

import { ModalContainer, ModalDivider } from "./styles";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const Modal = ({ onClose, open, title, children }) => {
  return (
    <ModalContainer onClose={onClose} open={open}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 32, top: 32 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ModalDivider />
      {children}
    </ModalContainer>
  );
};

export default Modal;
