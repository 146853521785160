import React from "react";
import { CardContainer, CardContent, CardImage } from "./styles";

const Card = ({ item }) => {
  return (
    <CardContainer style={item.styles}>
      <CardImage src={item.area.src} alt={item.area.alt} />
      <CardContent>
        <h2>{item.area.title}</h2>
        <p>{item.area.desc}</p>
      </CardContent>
    </CardContainer>
  );
};

export default Card;
