import React from "react";
import { Container, Wrapper, Logo, Content } from "./styles";

import logoAlma from "../../assets/images/logo_alma.png";
import ReactTyped from "react-typed";

const VideoSection = () => {
  const strings = [
    "Tecnologia com",
    "Inovação com",
    "Produto com",
    "Design com",
    "Agilidade com",
  ];

  return (
    <Container id="home">
      <Wrapper>
        <video autoPlay muted loop>
          <source src="https://media.graphassets.com/spG66ztTpucBKQVhhviJ" />
        </video>
        <Content>
          <ReactTyped
            strings={strings}
            typeSpeed={50}
            backSpeed={50}
            backDelay={250}
            loop
          />
          <Logo
            src={logoAlma}
            alt="Imagem da marca Alma. Na marca, destaque para a palavra alma em cor azul-escuro e logo abaixo, como tag, a palavra Sírio-Libanês em cor ciano."
          />
        </Content>
      </Wrapper>
    </Container>
  );
};

export default VideoSection;
