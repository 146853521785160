import React from "react";
import ReactGA from "react-ga4";
import { Container, TextCol, Wrapper } from "./styles";

import EnergyImg from "../../assets/images/alma-hsl-circulo-composicao.png";
import ArrowRight from "../../assets/icons/arrow_right_dark_blue.png";
import Modal from "../Modal";

const AboutSection = () => {
  const [open, setOpen] = React.useState(false);

  const handleModal = () => {
    setOpen((prevState) => !prevState);

    if (!open) {
      ReactGA.event({
        category: "Quem somos",
        action: "Abrir modal do Manifesto",
        label: "ClickedOnManifesto",
      });
    }
  };

  return (
    <Wrapper id="about">
      <Container>
        <TextCol>
          <h1>
            Somos <br /> Alma Sírio-Libanês
          </h1>
          <p>
            Nosso propósito é inspirar e impulsionar o{" "}
            <strong>
              ecossistema, fomentar o empreendedorismo, acelerar o
              desenvolvimento e incorporação de novas tecnologias, produtos e
              promover conexões em prol de uma vida plena e digna pra todos.
            </strong>
          </p>
          <span onClick={handleModal}>
            <p>Leia o manifesto</p>
            <img src={ArrowRight} alt="Ícone com seta para direita" />
          </span>
        </TextCol>
        <img
          src={EnergyImg}
          alt="Imagem do Ecossistema de Saúde Alma Sírio-Libanês representado por um círculo com diversos aspectos que englobam os dois pilares: 1) Hospital de ponta e 2) Startups e Empresas Tech."
        />
      </Container>
      <Modal onClose={handleModal} open={open} title="Manifesto">
        <p>
          Quando a maior parte do mundo não aceitava o voto feminino, <br />
          <strong>
            nossa instituição era fundada por um grupo de mulheres.
          </strong>
        </p>
        <p>
          Quando ainda não existia UTI no Brasil,{" "}
          <strong>nós criamos a primeira em um hospital privado.</strong>
        </p>
        <p>
          Quando casos desafiadores surgiram, nós realizamos procedimentos{" "}
          <strong>inéditos com excelência.</strong>
        </p>
        <p>
          <strong>Agora</strong>, nossa história ganha um{" "}
          <strong>novo capítulo</strong>. Queremos <strong>transformar</strong>{" "}
          o futuro da saúde.
        </p>
        <p>
          Nascemos com a coragem para questionar padrões e com ousadia para{" "}
          <strong>construir o novo.</strong>
        </p>
        <p>
          Esta será a <strong>ALMA</strong> de todos os nossos projetos de{" "}
          <strong>tecnologia e inovação.</strong>
        </p>
        <p>
          Uma nova marca para inspirar e impulsionar o{" "}
          <strong>
            ecossistema, fomentar o empreendedorismo e promover conexões em prol
            de uma vida plena e digna pra todos.
          </strong>
        </p>
        <p>
          Colaboração, proximidade, cuidado. <br /> Essa é a personalidade e a{" "}
          <strong>essência</strong> da nossa nova marca de inovação.
        </p>
        <p>
          Somos <strong>Alma Sírio-Libanês.</strong>
        </p>
      </Modal>
    </Wrapper>
  );
};

export default AboutSection;
