import React from "react";
import { Wrapper, Container, Image, Texts, SocialLinks } from "./styles";

import Alma from "../../assets/images/logo-alma-hsl-padrao.png";
import Email from "../../assets/icons/email.png";
import LinkedIn from "../../assets/icons/linkedin.png";
import Facebook from "../../assets/icons/facebook.png";
import YouTube from "../../assets/icons/youtube.png";
import Instagram from "../../assets/icons/instagram.png";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Image
          src={Alma}
          alt="Imagem da marca Alma. Na marca, destaque para a palavra alma em cor azul-escuro e logo abaixo, como tag, a palavra Sírio-Libanês em cor ciano."
        />
        <Texts>
          <p>
            <a href="https://hospitalsiriolibanes.org.br/politica-de-privacidade/">
              Política de Privacidade
            </a>
          </p>
          <p>© 2022 Alma Sírio-Libanês</p>
        </Texts>
        <SocialLinks>
          <a href="mailto:alma@hsl.org.br">
            <img src={Email} alt="Email" />
          </a>
          <a href="https://www.linkedin.com/company/hospitalsiriolibanes/mycompany/verification/">
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com/HospitalSirioLibanes/">
            <img src={Facebook} alt="Facebook" />
          </a>
          <a href="https://www.youtube.com/c/HospitalSirioLibanes">
            <img src={YouTube} alt="YouTube" />
          </a>
          <a href="https://www.instagram.com/hsiriolibanes/?hl=pt-br#">
            <img src={Instagram} alt="Instagram" />
          </a>
        </SocialLinks>
      </Container>
    </Wrapper>
  );
};

export default Footer;
