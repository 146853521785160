import styled from "styled-components";
import { Dialog } from "@mui/material";

export const ModalContainer = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 57.25rem;
    width: 100%;
    padding: 4.5rem 3.75rem 2rem;
    border-radius: 8px;

    @media (max-width: 767px) {
      padding: 2.25rem 1.5rem 1.5rem;
    }
  }

  h2 {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 116%;

    letter-spacing: -0.02em;

    color: #202538;
  }

  p {
    margin: 0 0 2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;

    color: #3d3b40;

    &:last-of-type {
      margin: 0;
    }
  }
`;

export const ModalDivider = styled.div`
  width: 100%;
  padding: 1.5px 0;
  background: linear-gradient(270deg, #8300ff 50%, #14d2e1 80%);
  margin: 1rem 0 1.5rem;
`;
