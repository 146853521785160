import styled from "styled-components";

export const Carousel = styled.div`
  width: 100%;
  height: 45rem;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;

  @media (max-width: 648px) {
    max-width: 20.5rem;
    height: 39.5rem;
    margin: 0 auto 2rem;
  }
`;

export const CarouselWrapper = styled.ul`
  --base-duration: 600ms;

  padding: 0;
  display: flex;
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
`;
