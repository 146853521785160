import React from "react";
import CarouselNav from "../CarouselNav";
import Tabs from "../Tabs";
import {
  Container,
  Content,
  ContentContainer,
  Heading,
  Inner,
  Logo,
  LogoShape,
  LogosWrapper,
  Tab,
  TabNav,
  TabsWrapper,
  Wrapper,
  NewsCardContainer,
  NewsCard,
  NewsCardTop,
  NewsCardTopRight,
  NewsCardBottom,
  NewsCardImage,
  NewsCardImageText,
} from "./styles";

import SofyaLogo from "../../assets/images/logo_sofya.png";
import AevoLogo from "../../assets/images/logo_aevo.png";
import AWSLogo from "../../assets/images/logo_aws.png";
import PremioInovacao from "../../assets/images/premio_inovacao.png";

const Ecosystem = () => {
  const [active, setActive] = React.useState(0);

  const tabs = [
    {
      title: "Na mídia",
    },
    {
      title: "Parceiros",
    },
  ];
  const length = tabs.length;

  const handlePrevClick = () => {
    const previous = active - 1;

    if (previous < 0) {
      setActive(length - 1);
    } else {
      setActive(previous);
    }
  };

  const handleNextClick = () => {
    const next = active + 1;

    if (next === length) {
      setActive(0);
    } else {
      setActive(next);
    }
  };

  const handleDotClick = (idx) => {
    setActive(idx);
  };

  return (
    <Wrapper id="ecosystem">
      <Container>
        <Heading>Ecossistema</Heading>
        <Inner>
          <TabsWrapper>
            <ContentContainer>
              <Tabs active={active} setActive={setActive} tabs={tabs} />
              <Content active={active}>
                <Tab>
                  Nossa transformação em saúde já ganhou visibilidade em
                  diversos portais de notícias. <br />
                  <br />
                  Veja alguns de nossos principais destaques na mídia:
                </Tab>
                <Tab>
                  Estamos abertos a parcerias para construir produtos e
                  soluções, que serão testados internamente e poderão ser
                  aplicados dentro e fora da nossa instituição. <br />
                  <br />
                  Conheça alguns de nossos parceiros:
                </Tab>
              </Content>
            </ContentContainer>
            <TabNav>
              <CarouselNav
                current={active}
                steps={tabs.length}
                prevClick={handlePrevClick}
                nextClick={handleNextClick}
                dotClick={handleDotClick}
              />
            </TabNav>
          </TabsWrapper>
          {active === 0 && (
            <NewsCardContainer>
              <NewsCardTop>
                <NewsCard card={1}>
                  <a
                    href="https://www.scrum.org/resources/hospital-sirio-libanes-uses-scrum-respond-covid-19-pandemic-reducing-losses-and"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hospital Sírio-Libanês usa Scrum para responder à COVID-19,
                    reduzindo perdas e oferecendo segurança aos trabalhadores da
                    linha de frente.
                  </a>{" "}
                  (Scrum.org)
                </NewsCard>
                <NewsCardTopRight>
                  <NewsCard card={2}>
                    <NewsCardImage>
                      <img
                        src={PremioInovacao}
                        alt="Prêmio Valor Inovação Brasil 2022"
                      />
                      <NewsCardImageText>
                        <a
                          href="https://www.strategyand.pwc.com/br/pt/inovacao-brasil-2022.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Top 100 Empresas mais inovadoras do Brasil
                        </a>{" "}
                        (Valor Econômico)
                      </NewsCardImageText>
                    </NewsCardImage>
                  </NewsCard>
                  <NewsCard card={3}>
                    <a
                      href="https://www.openstartups.net/site/ranking/rankings-categories-corps.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ranking Top 50 Empresas na categoria Saúde
                    </a>{" "}
                    (Open Corps)
                  </NewsCard>
                </NewsCardTopRight>
              </NewsCardTop>
              <NewsCardBottom>
                <NewsCard card={4}>
                  <a
                    href="https://economia.estadao.com.br/blogs/coluna-do-broad/sirio-libanes-tim-e-delloite-testam-5g-em-ambulancia-inteligente/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sírio-Libanês e Deloitte testam 5G em ambulância Inteligente
                  </a>{" "}
                  (Estadão)
                </NewsCard>
                <NewsCard card={5}>
                  <a
                    href="https://revistati.com.br/noticias/it-day-aponta-os-novos-rumos-da-ti"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IT Day debate sobre inovação disruptiva e A Nova TI
                  </a>{" "}
                  (TI Inside)
                </NewsCard>
              </NewsCardBottom>
            </NewsCardContainer>
          )}
          {active === 1 && (
            <LogosWrapper>
              <LogoShape>
                <a
                  href="https://aws.amazon.com/pt/solutions/case-studies/hospital-sirio-libanes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={AWSLogo} alt="AWS" />
                </a>
              </LogoShape>
              <LogoShape>
                <a
                  href="https://www.sofya.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={SofyaLogo} alt="Sofya" />
                </a>
              </LogoShape>
              <LogoShape>
                <a
                  href="https://aevo.com.br/connect/alma-hsl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={AevoLogo} alt="AEVO" />
                </a>
              </LogoShape>
            </LogosWrapper>
          )}
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default Ecosystem;
