import React, { useEffect, useState } from "react";
import { StepsGroup, Step } from "./styles";

const StepsNav = ({ current = 0, steps = 1, handleChange }) => {
  const [_current, setCurrent] = useState(current);

  useEffect(() => {
    setCurrent(current);
  }, [current]);

  const _handleClick = (index) => {
    setCurrent(index);
    if (typeof handleChange === "function") {
      handleChange(index);
    }
  };

  return (
    <StepsGroup>
      {[...Array(steps)].map((x, index) => {
        const type = _current === index ? "current" : "default";
        return (
          <Step type={type} key={index} onClick={() => _handleClick(index)} />
        );
      })}
    </StepsGroup>
  );
};

export default StepsNav;
