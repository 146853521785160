import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import Card from "../Card";
import CarouselNav from "../CarouselNav";

import { Carousel, CarouselWrapper } from "./styles";

const CardCarousel = ({ items }) => {
  const [length, setLength] = React.useState(0);
  const [current, setCurrent] = React.useState(0);
  const isDesktop = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    if (isDesktop) {
      const newLength = items.length - 2;
      setLength(newLength);
    } else {
      setLength(items.length);
    }
  }, [isDesktop, items.length]);

  const wrapperTransform = {
    transform: `translateX(-${current * (100 / items.length)}%)`,
  };

  const handlePrevClick = () => {
    const previous = current - 1;

    if (previous < 0) {
      setCurrent(length - 1);
    } else {
      setCurrent(previous);
    }
  };

  const handleNextClick = () => {
    const next = current + 1;

    if (next === length) {
      setCurrent(0);
    } else {
      setCurrent(next);
    }
  };

  const handleDotClick = (idx) => {
    setCurrent(idx);
  };

  return (
    <>
      <Carousel>
        <CarouselWrapper style={wrapperTransform}>
          {items.map((item, i) => (
            <Card key={i} item={item} />
          ))}
        </CarouselWrapper>
      </Carousel>
      <CarouselNav
        current={current}
        steps={length}
        prevClick={handlePrevClick}
        nextClick={handleNextClick}
        dotClick={handleDotClick}
      />
    </>
  );
};

export default CardCarousel;
