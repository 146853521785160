// BirthdayForm.js

import React, { useState } from "react";
import { apiLead } from "../../services/api";

import {
  Wrapper,
  Container,
  BoxOne,
  Image,
  BoxTwo,
  ContainerTitle,
  ContentTitle,
  Divider,
  Paragraph,
  Button,
  InputBox,
  Input,
  Label,
  InputRow,
  InputDiv,
} from "./styles";

import BirthdayBook from "../../assets/images/alma-hsl-birthday-book.png";

const BirthdayForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const ref = React.useRef(null);

  const handleNewCase = async (values) => {
    setLoading(true);
    try {
      const response = await apiLead.post("/lead", values);
      console.log("Resposta do Backend:", response.data);
      setSuccess(true);
      setLoading(false);
      ref.current.scrollIntoView({ behavior: "smooth" });
    } catch (e) {
      if (e.response) {
        console.error("Erro no Backend:", e.response.data);
      } else if (e.request) {
        console.error("Sem resposta do Backend:", e.request);
      } else {
        console.error("Erro durante a solicitação:", e.message);
      }
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleButtonClick = () => {
    // Verifica se a solicitação já foi enviada com sucesso
    if (!success) {
      // Se não foi enviada com sucesso, chama a função para enviar a solicitação
      handleNewCase(formData);
    }
  };

  return (
    <Wrapper id="part">
      <Container>
        <BoxOne>
          <Image
            src={BirthdayBook}
            alt="Imagem de livros em comemoração ao aniversário tech estágio."
          />
        </BoxOne>
        <BoxTwo>
          <ContainerTitle>
            <ContentTitle>
              Celebre conosco o 1º Aniversário <br />
              da Alma Sírio-Libanês!
            </ContentTitle>
          </ContainerTitle>

          <Paragraph>
            Neste ano de inovação e crescimento, temos o prazer de anunciar o
            pré-lançamento do nosso e-book:{" "}
            <b>
              Transformação Digital é uma Questão de Saúde: O Case de Agilidade
              do Sírio-Libanês.
            </b>{" "}
            <br />
            <br />
            Cadastre-se para receber o e-book que será lançado em breve.
          </Paragraph>

          <InputBox>
            <InputRow>
              <InputDiv>
                <Label htmlFor="firstName">Nome</Label>
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Digite seu nome"
                />
                <Divider />
              </InputDiv>
              <InputDiv>
                <Label htmlFor="lastName">Sobrenome</Label>
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Digite seu sobrenome"
                />
                <Divider />
              </InputDiv>
            </InputRow>
          </InputBox>

          <InputBox>
            <InputRow>
              <InputDiv>
                <Label htmlFor="email">E-mail</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Digite seu e-mail"
                />
                <Divider />
              </InputDiv>
              <InputDiv>
                <Label htmlFor="cellphone">Celular</Label>
                <Input
                  type="tel"
                  id="cellphone"
                  name="cellphone"
                  value={formData.cellphone}
                  onChange={handleInputChange}
                  placeholder="Digite seu celular"
                />
                <Divider />
              </InputDiv>
            </InputRow>
          </InputBox>

          <Button
            success={success}
            onClick={handleButtonClick}
            disabled={loading || success}
          >
            {loading
              ? "Enviando..."
              : success
              ? "Enviado com sucesso!"
              : "Inscreva-se"}
          </Button>
        </BoxTwo>
      </Container>
    </Wrapper>
  );
};

export default BirthdayForm;
