import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 87px;
  @media (max-width: 648px) {
    margin-top: 57px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 69.875rem;
  display: flex;
  align-items: center;
  margin-bottom: 5.375rem;

  @media (max-width: 648px) {
    max-width: calc(100% - 3rem);
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const BoxOne = styled.div``;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 124%;
  color: #202538;
  margin: 0;

  @media (max-width: 648px) {
    font-size: 1.5rem;
  }
`;

export const Image = styled.img`
  margin-top: 3.375rem;

  @media (max-width: 648px) {
    width: 100%;
    margin-top: 1.5rem;
  }
`;

export const BoxTwo = styled.div`
  margin: 2rem 0 0 3.875rem;

  @media (max-width: 648px) {
    margin: 1rem 0 0;
    width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 116%;
  letter-spacing: -0.02em;
  color: #202538;
`;

export const Divider = styled.div`
  width: 18.438rem;
  height: 0.313rem;
  background: linear-gradient(270deg, #8300ff 50%, #14d2e1 80%);
  margin-top: 1.5rem;

  @media (max-width: 648px) {
    width: 100%;
  }
`;

export const Subtitle = styled.h2`
  width: 22rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  color: #3d3b40;
  margin: 1.5rem 0 0;
`;

export const Paragraph = styled.p`
  width: 22rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #59585e;
  margin: 1.5rem 0 0;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 1.5rem;
  gap: 0.5rem;
  width: 19.5rem;
  height: 3rem;
  background: #00000014;
  border-radius: 31.25rem;
  border: none;
  margin: 1.875rem 0 2.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #00000052;
  text-decoration: none;

  /* &:hover {
    background-color: #ffff;
    color: #692996;
    border: 1px solid #692996;
  } */

  @media (max-width: 648px) {
    padding: 0;
    margin: 1rem 0 2rem;
    width: 100%;
  }
`;

export const Link = styled.div`
  margin-top: 1rem;
  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #2c6ef2;
    text-decoration: none;
  }

  img {
    margin-left: 0.583rem;
  }

  @media (max-width: 648px) {
    margin-bottom: 3.75rem;
  }
`;
